/**
 * @file Helpers for landside app
 * */

import { __ } from '@@/bits/intl'
import { buildUrlFromPath } from '@@/bits/location'
import { OzTabsWithSeparatorHrefMode } from '@@/library/v4/components/OzTabsWithSeparator.vue'
import { LandsidePage, ProductPageTab } from '@@/pinia/landside_navigation'

export enum HomePageCard {
  Welcome = 'welcome',
  FoodBlog = 'food-blog',
  Map = 'map',
  KanbanBoard = 'kanban-board',
  Timeline = 'timeline',
  TwitterReview = 'twitter-review',
  Portfolio = 'portfolio',
  WorldwideUsers = 'worldwide-users',
  OrgChart = 'org-chart',
  Schools = 'schools',
  Presentation = 'presentation',
  QAndA = 'q-and-a',
  PhotoAlbum = 'photo-album',
  AppReview = 'app-review',
  VideoPlaylist = 'video-playlist',
  FileSharing = 'file-sharing',
  NewsFeed = 'new-feed',
  Careers = 'careers',
}

export interface AppReview {
  name: string
  review: string
  rating: number
  url: string
}

export interface TwitterReview {
  name: string
  tweet: string
  username: string
  url: string
}

export interface SchoolLogo {
  name: string
  type: string
  country: string
  schoolUrl: string
  logoUrl: string
  logoWidth: number
  logoHeight: number
}

export interface HomePageWallFormatCardProps {
  title: string
  description?: string
  backgroundImage: {
    dark: string
    light: string
    originalWidth: number
    originalHeight: number
    alt?: string
  }
}

export interface HomePageWelcomeCardProps {
  description?: string
}

interface HomePageTwitterCardProps {
  name: string
  tweet: string
  username: string
  url: string
}

interface HomePageSchoolsCardProps {
  name: string
  type: string
  country: string
  schoolUrl: string
  logoUrl: string
  logoWidth: number
  logoHeight: number
}

interface HomePageReviewCardProps {
  name: string
  review: string
  rating: number
  url: string
}

export const homepageCards: {
  [HomePageCard.Welcome]: HomePageWelcomeCardProps
  [HomePageCard.FoodBlog]: HomePageWallFormatCardProps
  [HomePageCard.Map]: HomePageWallFormatCardProps
  [HomePageCard.KanbanBoard]: HomePageWallFormatCardProps
  [HomePageCard.Timeline]: HomePageWallFormatCardProps
  [HomePageCard.TwitterReview]: HomePageTwitterCardProps
  [HomePageCard.Portfolio]: HomePageWallFormatCardProps
  [HomePageCard.WorldwideUsers]: HomePageWallFormatCardProps
  [HomePageCard.OrgChart]: HomePageWallFormatCardProps
  [HomePageCard.Schools]: HomePageSchoolsCardProps[]
  [HomePageCard.Presentation]: HomePageWallFormatCardProps
  [HomePageCard.QAndA]: HomePageWallFormatCardProps
  [HomePageCard.PhotoAlbum]: HomePageWallFormatCardProps
  [HomePageCard.AppReview]: HomePageReviewCardProps
  [HomePageCard.VideoPlaylist]: HomePageWallFormatCardProps
  [HomePageCard.FileSharing]: HomePageWallFormatCardProps
  [HomePageCard.NewsFeed]: HomePageWallFormatCardProps
  [HomePageCard.Careers]: HomePageWallFormatCardProps
} = {
  [HomePageCard.Welcome]: {
    description: __('Make beautiful boards to collect, organize, and present anything.'),
  },
  [HomePageCard.FoodBlog]: {
    title: __('Food blog'),
    backgroundImage: {
      dark: 'https://padlet.net/homepage/v4/food_blog_stream_thumbnail_desktop_dark.webp',
      light: 'https://padlet.net/homepage/v4/food_blog_stream_thumbnail_desktop_light.webp',
      originalWidth: 1600,
      originalHeight: 936,
      alt: __(
        'A board with posts arranged in a single column. Each post displays an image of a dish, accompanied by a description and the name of the author.',
      ),
    },
  },
  [HomePageCard.Map]: {
    title: __('Map'),
    backgroundImage: {
      dark: 'https://padlet.net/homepage/v4/map_thumbnail_desktop_dark.webp',
      light: 'https://padlet.net/homepage/v4/map_thumbnail_desktop_light.webp',
      originalWidth: 1600,
      originalHeight: 936,
      alt: __('A board with pinned posts of where animals are in the zoo on a map background.'),
    },
  },
  [HomePageCard.KanbanBoard]: {
    title: __('Kanban board'),
    backgroundImage: {
      dark: 'https://padlet.net/homepage/v4/kanban_shelf_thumbnail_desktop_dark.webp',
      light: 'https://padlet.net/homepage/v4/kanban_shelf_thumbnail_desktop_light.webp',
      originalWidth: 1600,
      originalHeight: 936,
      alt: __(
        'A board separated into 3 sections: To Do, In Progress, and Done. Each column contains posts with text, images and videos.',
      ),
    },
  },
  [HomePageCard.Timeline]: {
    title: __('Timeline'),
    backgroundImage: {
      dark: 'https://padlet.net/homepage/v4/timeline_thumbnail_desktop_dark.webp',
      light: 'https://padlet.net/homepage/v4/timeline_thumbnail_desktop_light.webp',
      originalWidth: 1600,
      originalHeight: 936,
      alt: __(
        'A board with posts lined up in a horizontal line that demonstrates the evolution of art and culture over centuries.',
      ),
    },
  },
  [HomePageCard.TwitterReview]: {
    name: 'Kristina Gallagher',
    tweet:
      '@padlet is a great way to showcase student learning & experiences in the STEM classroom. Families can also easily stay connected.',
    username: 'STEMHES',
    url: 'https://twitter.com/STEMHES/status/1589263158061199360?s=20',
  },
  [HomePageCard.Portfolio]: {
    title: __('Portfolio'),
    backgroundImage: {
      dark: 'https://padlet.net/homepage/v4/portfolio_wall_thumbnail_desktop_dark.webp',
      light: 'https://padlet.net/homepage/v4/portfolio_wall_thumbnail_desktop_light.webp',
      originalWidth: 1600,
      originalHeight: 936,
      alt: __(
        'A board filled with art projects. It contains an illustration of Vincent van Gogh, and his iconic paintings such as the Starry Sky and Garden at Arles.',
      ),
    },
  },
  [HomePageCard.WorldwideUsers]: {
    title: __('40 million'),
    description: __('users across the globe'),
    backgroundImage: {
      dark: 'https://padlet.net/homepage/v4/user_metrics_thumbnail_desktop_dark.webp',
      light: 'https://padlet.net/homepage/v4/user_metrics_thumbnail_desktop_light.webp',
      originalWidth: 1600,
      originalHeight: 936,
    },
  },
  [HomePageCard.OrgChart]: {
    title: __('Org chart'),
    backgroundImage: {
      dark: 'https://padlet.net/homepage/v4/org_chart_canvas_thumbnail_desktop_dark.webp',
      light: 'https://padlet.net/homepage/v4/org_chart_canvas_thumbnail_desktop_light.webp',
      originalWidth: 1600,
      originalHeight: 936,
      alt: __(
        'A board filled with posts of the employees, each with a description and a face portrait. The hierarchical structure of the company is illustrated via the lines connecting various employees and the positions of the posts.',
      ),
    },
  },

  [HomePageCard.Schools]: [
    {
      name: 'Akron Public Schools',
      type: 'school',
      country: 'US',
      schoolUrl: 'https://www.akronschools.com/',
      logoUrl: 'https://padlet.net/school-logos/v2/akron_public_schools.png',
      logoWidth: 277,
      logoHeight: 234,
    },
    {
      name: 'J. Sterling Morton High School District 201',
      type: 'school',
      country: 'US',
      schoolUrl: 'https://www.morton201.org/',
      logoUrl: 'https://padlet.net/school-logos/v2/morton201.png',
      logoWidth: 2550,
      logoHeight: 3300,
    },
    {
      name: 'San Ramon Valley Unified School District',
      type: 'school',
      country: 'US',
      schoolUrl: 'https://www.srvusd.net',
      logoUrl: 'https://padlet.net/school-logos/v2/san_ramon_valley_unified_school_district.png',
      logoWidth: 1908,
      logoHeight: 1908,
    },
    {
      name: 'Pingry School',
      type: 'school',
      country: 'US',
      schoolUrl: 'https://www.pingry.org/',
      logoUrl: 'https://padlet.net/school-logos/v2/pingry_school.png',
      logoWidth: 2745,
      logoHeight: 644,
    },
  ],
  [HomePageCard.Presentation]: {
    title: __('Presentation'),
    backgroundImage: {
      dark: 'https://padlet.net/homepage/v4/presentation_thumbnail_desktop_dark.webp',
      light: 'https://padlet.net/homepage/v4/presentation_thumbnail_desktop_light.webp',
      originalWidth: 1600,
      originalHeight: 936,
      alt: __('A slide with its title and description on the left side, and a photo on the right side.'),
    },
  },
  [HomePageCard.QAndA]: {
    title: __('Q&A'),
    backgroundImage: {
      dark: 'https://padlet.net/homepage/v4/q_a_wall_thumbnail_desktop_dark.webp',
      light: 'https://padlet.net/homepage/v4/q_a_wall_thumbnail_desktop_light.webp',
      originalWidth: 1600,
      originalHeight: 936,
      alt: __('A board with many posts added in realtime.'),
    },
  },
  [HomePageCard.PhotoAlbum]: {
    title: __('Photo album'),
    backgroundImage: {
      dark: 'https://padlet.net/homepage/v4/photo_album_wall_thumbnail_desktop_dark.webp',
      light: 'https://padlet.net/homepage/v4/photo_album_wall_thumbnail_desktop_light.webp',
      originalWidth: 1600,
      originalHeight: 936,
      alt: __('A board with photos and videos of a vacation.'),
    },
  },
  [HomePageCard.AppReview]: {
    name: 'Hareem Idrees',
    review: 'I love it. Super easy interface. Very interactive. And amazing for organising things. Just amazing',
    rating: 5,
    url: 'https://padlet.com',
  },
  [HomePageCard.VideoPlaylist]: {
    title: __('Video playlist'),
    backgroundImage: {
      dark: 'https://padlet.net/homepage/v4/video_playlist_grid_thumbnail_desktop_dark.webp',
      light: 'https://padlet.net/homepage/v4/video_playlist_grid_thumbnail_desktop_light.webp',
      originalWidth: 1600,
      originalHeight: 936,
      alt: __('A board with many videos uploaded to it.'),
    },
  },
  [HomePageCard.FileSharing]: {
    title: __('Shared files'),
    backgroundImage: {
      dark: 'https://padlet.net/homepage/v4/file_sharing_grid_thumbnail_desktop_dark.webp',
      light: 'https://padlet.net/homepage/v4/file_sharing_grid_thumbnail_desktop_light.webp',
      originalWidth: 1600,
      originalHeight: 936,
      alt: __('Uploads of a document, a spreadsheet, an audio recording and a video recording.'),
    },
  },
  [HomePageCard.NewsFeed]: {
    title: __('News feed'),
    backgroundImage: {
      dark: 'https://padlet.net/homepage/v4/news_feed_stream_thumbnail_desktop_dark.webp',
      light: 'https://padlet.net/homepage/v4/news_feed_stream_thumbnail_desktop_light.webp',
      originalWidth: 1600,
      originalHeight: 936,
      alt: __('A board with posts arranged in a single column, sorted by date.'),
    },
  },
  [HomePageCard.Careers]: {
    title: __("We're hiring!"),
    description: __('Come work with us.'),
    backgroundImage: {
      dark: 'https://padlet.net/homepage/v4/careers_thumbnail_desktop_dark.webp',
      light: 'https://padlet.net/homepage/v4/careers_thumbnail_desktop_light.webp',
      originalWidth: 1600,
      originalHeight: 936,
    },
  },
}

// #region - Home Page V2
export interface CarouselItem {
  title: string
  description: string
  image: {
    url: string
    originalWidth: number
    originalHeight: number
  }
}

export const boardCarouselItems = [
  {
    title: '',
    description: __(
      'Screenshot of a Padlet board titled ’Exploring Easter Island KWL Chart,’ featuring sections for what’s known, wondered, and learned about Easter Island, with moai statues and related cultural details highlighted.',
    ),
    image: {
      url: 'https://padlet.net/landing-pages/home/v1/board_0.webp',
      originalWidth: 2880,
      originalHeight: 1620,
    },
  },
  {
    title: '',
    description: __(
      'Screenshot of a Padlet storyboard titled ’Chasing Hoops,’ displaying scenes of a girl’s basketball journey, from watching a game to playing outdoors, with scene descriptions included.',
    ),
    image: {
      url: 'https://padlet.net/landing-pages/home/v1/board_1.webp',
      originalWidth: 2880,
      originalHeight: 1620,
    },
  },
  {
    title: '',
    description: __(
      'Screenshot of a Padlet board titled ’Exploring the Seven Wonders,’ displaying a world map with pins and information cards on wonders like the Taj Mahal and Petra.',
    ),
    image: {
      url: 'https://padlet.net/landing-pages/home/v1/board_2.webp',
      originalWidth: 2880,
      originalHeight: 1620,
    },
  },
  {
    title: '',
    description: __(
      'Screenshot of a Padlet board titled ’Butterfly’s Magical Transformation,’ illustrating the lifecycle stages of a butterfly from egg to adult with respective images.',
    ),
    image: {
      url: 'https://padlet.net/landing-pages/home/v1/board_3.webp',
      originalWidth: 2880,
      originalHeight: 1620,
    },
  },
  {
    title: '',
    description: __(
      'Screenshot of a Padlet board titled ’Exploring Our Solar System,’ featuring illustrations and facts about celestial bodies such as the Sun, Mercury, Venus, and Earth.',
    ),
    image: {
      url: 'https://padlet.net/landing-pages/home/v1/board_4.webp',
      originalWidth: 2880,
      originalHeight: 1620,
    },
  },
  {
    title: '',
    description: __(
      'Screenshot of a Padlet slideshow slide showing a stylized painting of a moai statue and text about its archaeological significance.',
    ),
    image: {
      url: 'https://padlet.net/landing-pages/home/v1/board_5.webp',
      originalWidth: 2880,
      originalHeight: 1620,
    },
  },
]

export const sandboxCarouselItems = [
  {
    title: '',
    description: __(
      'Screenshot of a Padlet sandbox detailing the instructions for an educational activity. It shows text and images guiding students on how to take and annotate photos of book pages for class discussion.',
    ),
    image: {
      url: 'https://padlet.net/landing-pages/home/v1/sandbox_0.webp',
      originalWidth: 2634,
      originalHeight: 1484,
    },
  },
  {
    title: '',
    description: __(
      'Screenshot of a Padlet sandbox showcasing a Venn diagram activity comparing bananas and lemons with interactive sticky notes describing their attributes like color, texture, and taste.',
    ),
    image: {
      url: 'https://padlet.net/landing-pages/home/v1/sandbox_1.webp',
      originalWidth: 2880,
      originalHeight: 1620,
    },
  },
  {
    title: '',
    description: __(
      'Screenshot of a Padlet sandbox presenting a Jeopardy-style quiz game. It features categories such as ’Listen Here,’ ’Denver Nuggets,’ and ’Taylor’s Version’ with various point values from 200 to 800.',
    ),
    image: {
      url: 'https://padlet.net/landing-pages/home/v1/sandbox_2.webp',
      originalWidth: 4096,
      originalHeight: 2304,
    },
  },
  {
    title: '',
    description: __(
      'Screenshot of a Padlet sandbox illustrating a KWL (Know, Wonder, Learn) chart about the solar system. Sticky notes are placed under headings detailing known facts, questions, and learned information about planets.',
    ),
    image: {
      url: 'https://padlet.net/landing-pages/home/v1/sandbox_3.webp',
      originalWidth: 2560,
      originalHeight: 1442,
    },
  },
  {
    title: '',
    description: __(
      'Screenshot of a Padlet sandbox depicting an interactive map of a zoo. It features clickable areas for different animal exhibits like the ’Eagles Nest’ and ’Panda Habitat,’ designed to enhance user engagement and learning.',
    ),
    image: {
      url: 'https://padlet.net/landing-pages/home/v1/sandbox_4.webp',
      originalWidth: 4096,
      originalHeight: 2300,
    },
  },
  {
    title: '',
    description: __(
      'Screenshot of a Padlet sandbox titled ’Adventure to the Fairy Pools,’ which serves as a digital storybook’s cover page. It features vibrant illustrations and introductory text, inviting readers into a narrative journey.',
    ),
    image: {
      url: 'https://padlet.net/landing-pages/home/v1/sandbox_5.webp',
      originalWidth: 4096,
      originalHeight: 2297,
    },
  },
]
// #endregion

// #region - Navigation

export enum LandsidePageTab {
  Board = 'board',
  Sandbox = 'sandbox',
  Subscriptions = 'subscriptions',
  Templates = 'templates',
  Education = 'education',
}

export function buildLandsidePagePath(tab: LandsidePageTab): string {
  return `/site/${tab as string}`
}
// #endregion

/* Product Page */
export function buildProductPagePath(tab: ProductPageTab): string {
  return `/site/product/${tab as string}`
}

export const productNavigationTabs = [
  {
    text: __('How it works'),
    key: ProductPageTab.HOW_IT_WORKS,
    href: buildProductPagePath(ProductPageTab.HOW_IT_WORKS),
    hrefMode: OzTabsWithSeparatorHrefMode.HistoryPushState,
    testId: 'howitworksTab',
  },
  {
    text: __('For education'),
    key: ProductPageTab.EDUCATION,
    href: buildProductPagePath(ProductPageTab.EDUCATION),
    hrefMode: OzTabsWithSeparatorHrefMode.HistoryPushState,
    testId: 'educationTab',
  },
]

export const howItWorksCards = [
  {
    title: __('Default to beauty'),
    description: __(
      'Buttons, typography, and graphics are pixel perfect. Posts are sized and aligned automatically. Vivid file previews and hand-picked colors.',
    ),
    backgroundImage: {
      dark: 'https://padlet.net/how-it-works/v1/default_to_beauty_dark.webp',
      light: 'https://padlet.net/how-it-works/v1/default_to_beauty_light.webp',
      originalWidth: 1280,
      originalHeight: 720,
    },
  },
  {
    title: __('Express your style'),
    description: __(
      'Choose from 119 curated wallpapers or upload your own. Complementary post color options with timeless font choices. Customize your favicon with over a thousand emojis.',
    ),
    backgroundImage: {
      dark: 'https://padlet.net/how-it-works/v1/express_your_style_dark.webp',
      light: 'https://padlet.net/how-it-works/v1/express_your_style_light.webp',
      originalWidth: 1280,
      originalHeight: 720,
    },
  },
  {
    title: __('Post almost anything'),
    description: __(
      'Post images, audio, and video. Add any kind of file. Link YouTube videos, tweets, or any web page. Draw. Sing. Dance.',
    ),
    backgroundImage: {
      dark: 'https://padlet.net/how-it-works/v1/post_almost_anything_dark.webp',
      light: 'https://padlet.net/how-it-works/v1/post_almost_anything_light.webp',
      originalWidth: 1280,
      originalHeight: 720,
    },
  },
  {
    title: __('Organize your ideas visually'),
    description: __(
      'Lay out your information as a map, board, or freeform canvas. Group and sort posts by different properties.',
    ),
    backgroundImage: {
      dark: 'https://padlet.net/how-it-works/v1/organize_your_ideas_dark.webp',
      light: 'https://padlet.net/how-it-works/v1/organize_your_ideas_light.webp',
      originalWidth: 1280,
      originalHeight: 720,
    },
  },
  {
    title: __('Present and share in many ways'),
    description: __('Present as a slideshow. Share with a link or QR code. Embed anywhere. Export to PDF.'),
    backgroundImage: {
      dark: 'https://padlet.net/how-it-works/v1/present_and_share_dark.webp',
      light: 'https://padlet.net/how-it-works/v1/present_and_share_light.webp',
      originalWidth: 1280,
      originalHeight: 720,
    },
  },
  {
    title: __('Collaborate in real time'),
    description: __('Live updates on a shared screen. Comment, vote on, and like posts. Push and email notifications.'),
    backgroundImage: {
      dark: 'https://padlet.net/how-it-works/v1/collaborate_dark.webp',
      light: 'https://padlet.net/how-it-works/v1/collaborate_light.webp',
      originalWidth: 1280,
      originalHeight: 720,
    },
  },
  {
    title: __('Stay private or go public'),
    description: __(
      'Make your board private, password-protected, or totally public. Decide who can view and who can contribute.',
    ),
    backgroundImage: {
      dark: 'https://padlet.net/how-it-works/v1/stay_private_dark.webp',
      light: 'https://padlet.net/how-it-works/v1/stay_private_light.webp',
      originalWidth: 1280,
      originalHeight: 720,
    },
  },
  {
    title: __('Use anywhere on any device'),
    description: __('45 languages. Apps for web, Chromebook, Mac, PC, iOS, Android.'),
    backgroundImage: {
      dark: 'https://padlet.net/how-it-works/v1/use_anywhere_dark.webp',
      light: 'https://padlet.net/how-it-works/v1/use_anywhere_light.webp',
      originalWidth: 1280,
      originalHeight: 720,
    },
  },
]

/* Education Page */

export interface EducationClientLogo {
  name: string
  lightModeUrl: string
  lightModeLogoWidth: number
  lightModeLogoHeight: number
  darkModeUrl: string
  darkModeLogoWidth: number
  darkModeLogoHeight: number
}

export const educationCarouselItems = [
  {
    title: __('Classroom organization'),
    image: {
      url: 'https://padlet.net/education/v0/education_features_classroom_management.webp',
      originalWidth: 5120,
      originalHeight: 2880,
      alt: __('A board sectioned by the day of the week for planning weekly schedules.'),
    },
  },
  {
    title: __('Lessons'),
    image: {
      url: 'https://padlet.net/education/v0/education_features_lessons.webp',
      originalWidth: 5120,
      originalHeight: 2880,
      alt: __(
        'A slideshow on Electromagnetism, a topic tested in AP physics. The slides are generated from existing posts of a board that were used in lesson materials.',
      ),
    },
  },
  {
    title: __('Activities'),
    image: {
      url: 'https://padlet.net/education/v0/education_features_activities.webp',
      originalWidth: 5120,
      originalHeight: 2880,
      alt: __(
        'A board sectioned into 3 columns for students to share about what they know, what they want to know and what they have learnt on a Padlet.',
      ),
    },
  },
  {
    title: __('Assignments'),
    image: {
      url: 'https://padlet.net/education/v0/education_features_assignments.webp',
      originalWidth: 5120,
      originalHeight: 2880,
      alt: __('A map format padlet used for History class, with posts of various locations.'),
    },
  },
  {
    title: __('Professional development'),
    image: {
      url: 'https://padlet.net/education/v0/education_features_professional_development.webp',
      originalWidth: 5120,
      originalHeight: 2880,
      alt: __('A canvas format padlet for staff brainstorming session.'),
    },
  },
  {
    title: __('Communication'),
    image: {
      url: 'https://padlet.net/education/v0/education_features_communication.webp',
      originalWidth: 5120,
      originalHeight: 2880,
      alt: __('A padlet used as a bulletin board for distributing important information.'),
    },
  },
]

export enum EducationInfoSection {
  TEACHER = 'Teacher',
  ADMINISTRATOR = 'Administrator',
}

export interface EducationSectionCardContent {
  title: string
  description: string
  image: {
    dark: string
    light: string
    originalWidth: number
    originalHeight: number
  }
}

export const educationSectionCards: Record<EducationInfoSection, EducationSectionCardContent[]> = {
  [EducationInfoSection.TEACHER]: [
    {
      title: __('Beautiful by default'),
      description: __('Stunning backgrounds. Clean fonts. Pixel perfect designs. Straight out of the box.'),
      image: {
        dark: 'https://padlet.net/how-it-works/v1/default_to_beauty_dark.webp',
        light: 'https://padlet.net/how-it-works/v1/default_to_beauty_light.webp',
        originalWidth: 1280,
        originalHeight: 720,
      },
    },
    {
      title: __('Get things done fast'),
      description: __(
        'One click creation with a gallery full of useful templates. Lightning fast uploads. Keyboard shortcuts.',
      ),
      image: {
        dark: 'https://padlet.net/education/v0/fast_uploads_dark.webp',
        light: 'https://padlet.net/education/v0/fast_uploads_light.webp',
        originalWidth: 5120,
        originalHeight: 2880,
      },
    },
    {
      title: __('Collaborate in person or remotely'),
      description: __('Real time updates on a shared screen. Students can comment, vote on, and like posts.'),
      image: {
        dark: 'https://padlet.net/how-it-works/v1/collaborate_dark.webp',
        light: 'https://padlet.net/how-it-works/v1/collaborate_light.webp',
        originalWidth: 1280,
        originalHeight: 720,
      },
    },
    {
      title: __('Engage safely'),
      description: __('Control student permissions. Control privacy settings. Automated content moderation.'),
      image: {
        dark: 'https://padlet.net/how-it-works/v1/stay_private_dark.webp',
        light: 'https://padlet.net/how-it-works/v1/stay_private_light.webp',
        originalWidth: 1280,
        originalHeight: 720,
      },
    },
    {
      title: __('Everything in one place'),
      description: __('Prep for your lessons, lead your class, and evaluate your students all with one tool.'),
      image: {
        dark: 'https://padlet.net/education/v0/dashboard2_dark.webp',
        light: 'https://padlet.net/education/v0/dashboard2_light.webp',
        originalWidth: 5120,
        originalHeight: 2880,
      },
    },
    {
      title: __('Student choice'),
      description: __('Students can create with text, audio, video, or images on the device of their choice.'),
      image: {
        dark: 'https://padlet.net/how-it-works/v1/post_almost_anything_dark.webp',
        light: 'https://padlet.net/how-it-works/v1/post_almost_anything_light.webp',
        originalWidth: 1280,
        originalHeight: 720,
      },
    },
  ],
  [EducationInfoSection.ADMINISTRATOR]: [
    {
      title: __('Avoid technology overload'),
      description: __('Replace 20 tools with the 1 tool your teachers are already using.'),
      image: {
        dark: 'https://padlet.net/education/v0/layouts.webp',
        light: 'https://padlet.net/education/v0/layouts.webp',
        originalWidth: 5120,
        originalHeight: 2880,
      },
    },
    {
      title: __('Easy to onboard and manage'),
      description: __('Provision licenses with invite links, CSV upload, LMS, or SSO. Google, Microsoft, SAML.'),
      image: {
        dark: 'https://padlet.net/education/v0/user_list_dark.webp',
        light: 'https://padlet.net/education/v0/user_list_light.webp',
        originalWidth: 5120,
        originalHeight: 2880,
      },
    },
    {
      title: __('Integrate with your LMS and apps'),
      description: __('Canvas, Moodle, Blackboard, Blackbaud, Schoology, Google Classroom, Microsoft Teams.'),
      image: {
        dark: 'https://padlet.net/education/v0/teams.webp',
        light: 'https://padlet.net/education/v0/teams.webp',
        originalWidth: 5120,
        originalHeight: 2880,
      },
    },
    {
      title: __('Use anywhere on any device'),
      description: __('45 languages. Apps for web, Chromebook, Mac, PC, iOS, Android.'),
      image: {
        dark: 'https://padlet.net/how-it-works/v1/use_anywhere_dark.webp',
        light: 'https://padlet.net/how-it-works/v1/use_anywhere_light.webp',
        originalWidth: 1280,
        originalHeight: 720,
      },
    },
    {
      title: __('Exceptional service'),
      description: __(
        'Contact support 24/7. Knowledge base, email, WhatsApp, Zoom, professional development, webinars.',
      ),
      image: {
        dark: 'https://padlet.net/education/v0/help_dark.webp',
        light: 'https://padlet.net/education/v0/help_light.webp',
        originalWidth: 5120,
        originalHeight: 2880,
      },
    },
    {
      title: __('Private, secure and compliant'),
      description: __('Compliant with SOC 2, GDPR, FERPA, Ed Law 2D, and more. Control what content can be shared.'),
      image: {
        dark: 'https://padlet.net/education/v0/secure_dark.webp',
        light: 'https://padlet.net/education/v0/secure_light.webp',
        originalWidth: 5120,
        originalHeight: 2880,
      },
    },
  ],
}

export interface EducationReview {
  avatar: string
  username: string
  tweet: string
  isLogoHorizontal: boolean
  logoLight: string
  logoLightWidth: number
  logoLightHeight: number
  logoDark: string
  logoDarkWidth: number
  logoDarkHeight: number
}

export const defaultEducationReviews: EducationReview[] = [
  {
    avatar: 'https://padlet.net/education/v0/reviews/caroline_avatar.webp',
    username: 'carolinemikovit',
    tweet: __(
      "Padlet is a great educational tool used to upload, organize, and share documents with your class and co-workers. This website helps to keep all your records in one place that's accessible to everyone!",
    ),
    isLogoHorizontal: false,
    logoLight: 'https://padlet.net/education/v0/reviews/fairfield_uni_logo_light.webp',
    logoLightWidth: 274,
    logoLightHeight: 256,
    logoDark: 'https://padlet.net/education/v0/reviews/fairfield_uni_logo_dark.webp',
    logoDarkWidth: 282,
    logoDarkHeight: 256,
  },
  {
    avatar: 'https://padlet.net/education/v0/reviews/mr_das_avatar.webp',
    username: 'shiv_teaching',
    tweet: __(
      'Recommended website for #geography learning- %{padletUrl}<br>My classes have been using padlet to share ideas & contribute knowledge in different formats (statements, gifs, links, drawings, images). You can use padlet as a digital notice board for your lessons',
      {
        padletUrl: 'https://padlet.com',
      },
    ),
    isLogoHorizontal: false,
    logoLight: 'https://padlet.net/education/v0/reviews/boroughmuir_highschool_logo_light.webp',
    logoLightWidth: 234,
    logoLightHeight: 256,
    logoDark: 'https://padlet.net/education/v0/reviews/boroughmuir_highschool_logo_light.webp',
    logoDarkWidth: 234,
    logoDarkHeight: 256,
  },
  {
    avatar: 'https://padlet.net/education/v0/reviews/ghannia_avatar.webp',
    username: 'ghanniab',
    tweet: __(
      'Used @padlet for the first time today while delivering a public legal education seminar on GBV. It’s changed the legal education game for me… anonymous posting, minimal and effective user interface. A+ educational tool.',
    ),
    isLogoHorizontal: true,
    logoLight: 'https://padlet.net/education/v0/reviews/barbra_schlifer_logo_light.webp',
    logoLightWidth: 648,
    logoLightHeight: 192,
    logoDark: 'https://padlet.net/education/v0/reviews/barbra_schlifer_logo_light.webp',
    logoDarkWidth: 648,
    logoDarkHeight: 192,
  },
  {
    avatar: 'https://padlet.net/education/v0/reviews/mcallister_avatar.webp',
    username: 'MsTMcAllister',
    tweet: __(
      'I loved learning about Padlet during this course. I think this is a tool I will use often in my future classroom. I like that you can use it to create full lessons, as well as using it as a class discussion board. It facilitates learning and collaboration!',
    ),
    isLogoHorizontal: true,
    logoLight: 'https://padlet.net/education/v0/reviews/kennesaw_state_university_light.webp',
    logoLightWidth: 802,
    logoLightHeight: 192,
    logoDark: 'https://padlet.net/education/v0/reviews/kennesaw_state_university_light.webp',
    logoDarkWidth: 802,
    logoDarkHeight: 192,
  },
]

/* Subscription Page */

export enum SubscriptionFilter {
  ALL = 'All',
  HOME = 'Home',
  BUSINESS = 'Business',
  EDUCATION = 'Education',
}

export const subscriptionHashToFilterType = {
  '#all': SubscriptionFilter.ALL,
  '#home': SubscriptionFilter.HOME,
  '#business': SubscriptionFilter.BUSINESS,
  '#education': SubscriptionFilter.EDUCATION,
}

export const subscriptionFilters = [
  {
    filterType: SubscriptionFilter.ALL,
    text: __('All'),
    ariaLabel: __('Filter subscription by All'),
    href: '#all',
  },
  {
    filterType: SubscriptionFilter.HOME,
    text: __('Home'),
    ariaLabel: __('Filter subscription by Home'),
    href: '#home',
  },
  {
    filterType: SubscriptionFilter.BUSINESS,
    text: __('Business'),
    ariaLabel: __('Filter subscription by Business'),
    href: '#business',
  },
  {
    filterType: SubscriptionFilter.EDUCATION,
    text: __('Education'),
    ariaLabel: __('Filter subscription by Education'),
    href: '#education',
  },
]

export function buildSubscriptionPagePath(filter: SubscriptionFilter): string {
  return `/site/subscriptions#${filter.toLowerCase()}`
}

// Have to put howItWorksUserCards after buildSubscriptionPagePath
export const howItWorksUserCards = [
  {
    title: __('Home'),
    description: __('Foodies, photographers, writers, travelers. Family, friends, and teammates.'),
    illustration: {
      dark: 'https://padlet.net/how-it-works/v1/home_illustration_dark.webp',
      light: 'https://padlet.net/how-it-works/v1/home_illustration_light.webp',
      originalWidth: 4000,
      originalHeight: 1128,
    },
    landsidePage: LandsidePage.Subscriptions,
    url: buildUrlFromPath(buildSubscriptionPagePath(SubscriptionFilter.HOME)),
  },
  {
    title: __('Business'),
    description: __('Small businesses, startups, and enterprises.'),
    illustration: {
      dark: 'https://padlet.net/how-it-works/v1/business_illustration_dark.webp',
      light: 'https://padlet.net/how-it-works/v1/business_illustration_light.webp',
      originalWidth: 4000,
      originalHeight: 1128,
    },
    landsidePage: LandsidePage.Subscriptions,
    url: buildUrlFromPath(buildSubscriptionPagePath(SubscriptionFilter.BUSINESS)),
  },
  {
    title: __('Education'),
    description: __('Classrooms, K-12 schools, districts, universities, and adult education.'),
    illustration: {
      dark: 'https://padlet.net/how-it-works/v1/education_illustration_dark.webp',
      light: 'https://padlet.net/how-it-works/v1/education_illustration_light.webp',
      originalWidth: 4000,
      originalHeight: 1128,
    },
    landsidePage: LandsidePage.Product,
    productPageTab: ProductPageTab.EDUCATION,
    url: buildUrlFromPath(buildProductPagePath(ProductPageTab.EDUCATION)),
  },
]

/* Others */
export function sanitizeTitle(title: string): string {
  return title.replace(/\s+/g, '').replace(/\W/g, '')
}

export const subscriptionFaqQuestionsAndAnswers = [
  {
    question: __('Can I use Padlet for free?'),
    answer: __('Yes! With a free account, you can have up to 3 active padlets.'),
  },
  {
    question: __('Who is considered a teacher?'),
    answer: __(
      'Anyone employed with a school is considered a teacher. This includes administrators, assistants, and support staff.',
    ),
  },
  {
    question: __('I need to purchase Padlet for fewer than 10 teachers. What subscription should they use?'),
    answer: __(
      'The smallest Padlet for Schools subscription starts at 10 teacher accounts, but it also includes student accounts, LMS integrations, increased security, and other education-friendly features, so you should still consider it with fewer than 10 teachers. If you only have 2 teachers who need a subscription and want to manage student accounts and monitor student usage, try out the Classroom subscription. Otherwise, you can purchase personal subscriptions for each teacher.',
    ),
  },
  {
    question: __('We are a school. Should we buy a Team or a School subscription?'),
    answer: __(
      'Schools, districts, and universities should use Padlet for Schools. This plan includes student accounts, LMS integrations, increased security, and other education-friendly features.',
    ),
  },
  {
    question: __('Will Padlet integrate into our Learning Management System (LMS)?'),
    answer: __(
      'Yes. Padlet is LTI 1.3 certified and integrates with most major LMSes, including Moodle, Canvas, Blackboard, Brightspace, Schoology, itslearning, and Google Classroom.',
    ),
  },
  {
    question: __('Does Padlet support rostering?'),
    answer: __('Yes. Padlet supports rostering via ClassLink, Clever, and OneRoster.'),
  },
  {
    question: __('What payment methods do you accept? Do you accept purchase orders?'),
    answer: __(
      'We accept credit and debit cards and PayPal. For purchases over %{amount}, we also accept bank transfers and checks.',
      {
        amount: '$1000',
      },
    ),
  },
  {
    question: __('Can I get an official quotation?'),
    answer: __(
      'Yes, you can request an official quotation for a school plan <a id="faq-open-quote-modal" style="color: inherit;text-decoration: underline solid; cursor:pointer">here</a>.',
    ),
  },
  {
    question: __('What is your refund policy?'),
    answer: __(
      'For personal subscriptions, you can cancel an annual plan within the first 90 days or a monthly plan at any time, and we will send you a prorated refund for the remaining time of your subscription. If you cancel a school subscription in the first 30 days, we will send you a refund. If your situation doesn’t fall into one of the scenarios above, please <a href="https://padlet.com/contact-us" style="color: inherit;text-decoration: underline solid;">reach out to us</a> and we will find a solution that is fair. You can read more about our refund policy <a href="https://padlet.help/article/wtam7ene7m-what-is-your-refund-policy" style="color: inherit;text-decoration: underline solid;">here</a>.',
    ),
  },
]
